<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <div class="form-group">
              <label>
                Marca:
              </label>
              <validation-provider
                  name="Marca"
                  :rules="{ required: true }"
                  :customMessages="{
                  required: 'Es un campo requerido'
                }"
                  v-slot="validationContext"
              >
                <input type="text" v-model="registro.marca" class="form-control"/>
                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <div class="form-group">
              <label>
                Descripción:
              </label>
                <input type="text" v-model="registro.descripcion" class="form-control"/>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <div class="form-group">
              <label>
                Idioma:
              </label>
              <validation-provider
                  name="Idioma"
                  :rules="{ required: true }"
                  :customMessages="{
                  required: 'Es un campo requerido'
                }"
                  v-slot="validationContext"
              >
                <selectIdioma @ActualizandoId="setIdioma"/>
                <input type="hidden" v-model="registro.eco_idioma_id"/>

                <span style="color: red; font-size: small">
                  {{ validationContext.errors[0] }}
                </span>
              </validation-provider>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <div class="form-group">
      <button type="button" class="btn btn-primary mr-2" @click="registrar()">
        <i class="fa fa-floppy-o" aria-hidden="true"></i>
        Guardar
      </button>
    </div>
    <!-- Tabla  modificar-->
    <CCard>
      <CCardBody>
        <div class="table-responsive">
          <table class="table" id="registros-table">
            <thead>
            <tr>
              <th scope="col">Marca</th>
              <th scope="col">Idioma</th>
              <th scope="col">Descripción</th>
              <th scope="col">Acción</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="valores in registros" :key="valores.id">
              <td v-text="valores.marca"></td>
              <td v-text="valores.idioma"></td>
              <td v-text="valores.descripcion"></td>
              <td>
                <div class="btn-group">
                  <a @click="eliminar(valores.id)" class="btn btn-secondary btn-xs">
                    <i class="cil-trash"></i>
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>
    <!-- END: Tabla -->
  </div>
</template>
<script>
import httpService from "@/services/httpService"
import selectIdioma from '@/components/SelectIdioma.vue'

export default {
  components: {
    selectIdioma
  },
  props: {
    eco_marca_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {},
      registros: []
    }
  },
  methods: {
    setIdioma(value) {
      this.registro.eco_idioma_id = value
    },

    async eliminar(id) {
      let url = 'eco-marcas-traduccion/' + id
      let response = await httpService.delete(url)
      this.$vToastify.success('Operación realizada con éxito', 'Éxito')
      await this.listar();
    },

    async registrar() {
      try {
        this.registro.eco_marca_id = this.eco_marca_id
        let url = 'eco-marcas-traduccion'
        let response = await httpService.post(url, this.registro)
        this.$vToastify.success('Operación realizada con éxito')
        await this.listar()
      } catch (error) {
        console.log(error)
      }
    },

    async listar() {
      let url = 'eco-marcas-traduccion?page=0&buscar=' + this.eco_marca_id + '&criterio=eco_marcas_traduccion.eco_marca_id'
      let response = await httpService.get(url)
      let respuesta = response.data
      this.registros = respuesta.registros.data
      this.pagination = respuesta.pagination
    }
  },
  created() {
    this.listar()
  }
}
</script>